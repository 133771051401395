import React from "react"
import WhatLayout from "../../components/WhatLayout";
import oceanAll from "../../images/what/ocean/ocean-alle.png";
import ocean1 from "../../images/what/ocean/ocean-1.png";
import ocean1c from "../../images/what/ocean/ocean-1c.png";
import ocean2 from "../../images/what/ocean/ocean-2.png";
import ocean2c from "../../images/what/ocean/ocean-2c.png";
import ocean3 from "../../images/what/ocean/ocean-3.png";
import ocean3c from "../../images/what/ocean/ocean-3c.png";
import ocean4 from "../../images/what/ocean/ocean-4.png";
import ocean4c from "../../images/what/ocean/ocean-4c.png";
import ocean5 from "../../images/what/ocean/ocean-5.png";
import ocean5c from "../../images/what/ocean/ocean-5c.png";
import ocean6 from "../../images/what/ocean/ocean-6.png";
import ocean6c from "../../images/what/ocean/ocean-6c.png";

const Page = () => {

  return (
    <WhatLayout
      header={"Be my Ocean"}
      images={[
        oceanAll,
        ocean1,
        ocean1c,
        ocean2,
        ocean2c,
        ocean3,
        ocean3c,
        ocean4,
        ocean4c,
        ocean5,
        ocean5c,
        ocean6,
        ocean6c
      ]}
    />
  );

};

export default Page;
